@import url(https://fonts.googleapis.com/css2?family=Cabin:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap);
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@layer components {
  .nav-bar-elements {
    @apply h-[72px] w-[300px] rounded-lg text-secondary-75 hover:bg-secondary-50 hover:text-secondary-300 flex justify-center items-center hover:rounded-xl transition-all duration-300 ease-linear cursor-pointer;
  }

  .nav-bar-elements-clicked {
    @apply h-[72px] w-[300px] rounded-lg bg-secondary-50 text-secondary-300 flex items-center justify-center;
  }

  .nav-bar-elements-logout {
    @apply h-[72px] w-[300px] rounded-lg hover:bg-danger-50 hover:text-danger-300 flex justify-center items-center hover:rounded-xl transition-all duration-300 ease-linear cursor-pointer;
  }

}