.places-container {
    width: 100%;
}

.map-container {
    width: 100%;
    height: 100%;
    border-radius: 12px;
    border: 1px solid rgba(0, 0, 0, 0.3);
}

.combobox-input {
    width: 100%;
    margin-bottom: 10px;
    padding: 0.5rem;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.3);
}